import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next'
import { Form, Icon, Input, Button, Row, Col, Modal, Select } from 'antd'

import actions from '@client/actions'
import { AppContainer } from '@client/containers/index'
import ROLE from '@server/constants/roles'

import styles from './Login.styl'
import blueIcon from './images/blue-icon.png'

const { Item } = Form
const { Option } = Select

const mapStateToProps = (state) => ({
  isLogin: state.auth.isLogin,
  error: state.error.data,
  competitionList: state.competitionList.data,
  competitionListLoadingStatus: state.competitionList.loadingStatus,
})

const mapDispatchToProps = (dispatch) => {
  return { actions: bindActionCreators(actions, dispatch) }
}

class Login extends React.Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    isLogin: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    competitionList: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {}

  state = {
    isForgetPasswordModalVisible: false,
  }

  componentDidMount() {
    this.props.actions.getCompetitionList({
      sort: '-create_time',
    })
    if (this.emailInput) {
      this.emailInput.focus()
    }
  }

  componentDidUpdate(prevProps) {
    const { competitionList } = this.props
    if (competitionList !== prevProps.competitionList && competitionList.length > 0) {
      this.props.form.setFieldsValue({
        'login.competition': competitionList[0].id,
        'forgetPassword.competition': competitionList[0].id,
      })
    }
  }

  handleLoginClick = (e) => {
    this.props.form.validateFieldsAndScroll(
      ['login'],
      { scroll: { offsetTop: '100' } },
      (err, formData) => {
        if (err) return

        this.props.actions.login({
          competitionId: formData.login.competition,
          email: formData.login.email,
          password: formData.login.password,
        })
      },
    )
  }

  handleForgetPassword = () => {
    this.props.form.validateFieldsAndScroll(
      ['forgetPassword'],
      { scroll: { offsetTop: '100' } },
      (err, formData) => {
        if (err) return

        this.props.actions
          .forgetPassword({
            email: formData.forgetPassword.email,
            competitionId: formData.forgetPassword.competition,
          })
          .then((res) => {
            if (!res) return
            const {
              payload: {
                data: { email },
              },
            } = res
            const { t } = this.props
            this.props.form.resetFields(['forgetPassword'])
            this.setState({ isForgetPasswordModalVisible: false })
            Modal.success({
              title: t('忘記密碼'),
              content: `${t('修改密碼的連結已寄送到您的信箱')}（${email}）`,
              okText: t('確認'),
            })
          })
      },
    )
  }

  render() {
    const {
      isLogin,
      history,
      competitionList,
      form: { getFieldDecorator },
      t,
    } = this.props

    if (isLogin) {
      if (document.referrer && document.referrer.indexOf(window.location.hostname) > -1) {
        history.goBack()
      } else {
        window.location.href = '/'
      }
    }
    const nonEmptyRule = [{ required: true, message: t('必填') }]

    return (
      <AppContainer>
        <Helmet>
          <title>{t('登入')}</title>
        </Helmet>
        <Row type="flex" justify="center">
          <Col xs={20} sm={12} style={{ textAlign: 'center' }}>
            <img className={styles.icon} src={blueIcon} />
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col xs={20} sm={12}>
            <Form className={styles.form}>
              <Item>
                {getFieldDecorator(
                  'login.competition',
                  {},
                )(
                  <Select placeholder={t('屆數')}>
                    {competitionList.map((competition) => {
                      return (
                        <Option key={competition.id} value={competition.id}>
                          {competition.name}
                        </Option>
                      )
                    })}
                    <Option value={ROLE.ADMIN}>{ROLE.ADMIN}</Option>
                  </Select>,
                )}
              </Item>
              <Item>
                {getFieldDecorator('login.email', {
                  rules: nonEmptyRule,
                })(
                  <Input
                    ref={(input) => {
                      this.emailInput = input
                    }}
                    prefix={<Icon type="user" />}
                    placeholder={t('帳號')}
                  />,
                )}
              </Item>
              <Item>
                {getFieldDecorator('login.password', {
                  rules: nonEmptyRule,
                })(<Input prefix={<Icon type="lock" />} type="password" placeholder={t('密碼')} />)}
              </Item>
              <div style={{ textAlign: 'right' }}>
                <span
                  className={styles.linkStyle}
                  onClick={() => this.setState({ isForgetPasswordModalVisible: true })}
                >
                  {t('忘記密碼')}？
                </span>
              </div>
              <Item>
                <Button
                  htmlType="submit"
                  className="primary"
                  onClick={this.handleLoginClick}
                  style={{ width: '100%' }}
                >
                  {t('登入')}
                </Button>
              </Item>

              <Modal
                title={t('忘記密碼')}
                visible={this.state.isForgetPasswordModalVisible}
                okText={t('確認')}
                onOk={this.handleForgetPassword}
                cancelText={t('取消')}
                onCancel={() => this.setState({ isForgetPasswordModalVisible: false })}
              >
                <Item label={t('請輸入申請填寫的電子信箱')}>
                  {getFieldDecorator('forgetPassword.email', {
                    rules: [
                      ...nonEmptyRule,
                      {
                        type: 'email',
                        message: t('不合法的信箱'),
                      },
                    ],
                  })(<Input prefix={<Icon type="mail" />} type="email" />)}
                </Item>
                <Item label={t('屆數')}>
                  {getFieldDecorator('forgetPassword.competition', {
                    rules: nonEmptyRule,
                  })(
                    <Select placeholder={t('屆數')} style={{ width: '100%' }}>
                      {competitionList.map((competition) => {
                        return (
                          <Option key={competition.id} value={competition.id}>
                            {competition.name}
                          </Option>
                        )
                      })}
                    </Select>,
                  )}
                </Item>
              </Modal>
            </Form>
          </Col>
        </Row>
      </AppContainer>
    )
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create(),
  withTranslation(),
)(Login)
